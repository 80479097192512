.posts-centered-container {
  height: 7em;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

.posts-centered-container p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) }

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
